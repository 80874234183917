.ov__container {
    padding: 20px;
}

.ov__top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    margin-top: 10px;
}

.ov__img__container {
    width: 160px;
    height: 160px;
    border-radius: 100%;
    background-image: url("../../../public/me.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #e7e7e9;
    /* margin-right: 20px; */
}

.ov__wrap__p {
    flex: 1;
}

.ov__wrap__p p {
    text-indent: 40px;
    margin-top: 20px;

}

.ov__line {
    width: 1px;
    height: 200px;
    background-color: #c7c7c9;
    margin: 0 20px;
}

.ov__name {
    font-weight: 700;
    font-size: 20px;
}

.dark__text {
    color: white;
}

.ov__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ov__body__position {
    font-size: 20px;
    font-weight: 700;
    margin-top: 70px;
}

.ov__wrap__skill {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 20px;
}

.ov__skill__title {
    width: 65px;
    font-size: 16px;
    font-weight: 500;
    background-color: #e7e7e9;
    /* padding: 2px 8px; */
    border-radius: 5px;
    text-align: center;
}

.ov__skill__content {
    display: flex;
    justify-content: space-around;
    align-items: self-start;
}

.lightmode__ul {
    margin-top: 20px;
    padding: 30px 70px;
    border-radius: 10px;
    background-color: #f4f4f6;
}

.darkmode__ul {
    margin-top: 20px;
    padding: 30px 70px;
    border-radius: 10px;
    /* background-color: #323232; */
    background-color: rgb(32, 32, 34);
    border: 1px solid #2e2e30;
}

.lightmode__ul span, .darkmode__ul span {
    font-weight: 600;
}

.ov__skill__content li {
    list-style: none;
    text-indent: 15px;
    margin-top: 5px;
}

.ov__skill__wrap__sre {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ov__wrap__contact {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
}

.ov__contact {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    color: black;
    transition: .3s;
    cursor: pointer;
    margin-bottom: 5px;
}

.dark__contact {
    color: white;
}

.ov__contact img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.ov__contact:hover {
    background-color: #e7c56e;
}

.dark__contact:hover {
    background-color: #3d7fa1;
}

.contact__row {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 400px) {
    .ov__top {
        flex-direction: column;
    }
    .ov__line {
        display: none;
    }
    .ov__wrap__p {
        margin: 10px 0;
        text-align: center;
    }
    .ov__wrap__p p {
        text-align: center;
        text-indent: 0;
    }
    .contact__row {
        flex-direction: column;
        margin-top: 10px;
    }
    .ov__body__position {
        text-align: center;
    }
    .ov__skill__content {
        flex-direction: column;
    }
    .section__bottom, .outter__bottom {
        height: fit-content;
    }
    .lightmode__ul, .darkmode__ul {
        width: 100%;
        padding: 10px;
    }
    .ov__skill__content, .ov__skill__wrap__sre {
        width: 100%;
    }
}
@media screen and (max-width: 900px) {
    .ov__top {
        flex-direction: column;
    }
    .ov__line {
        display: none;
    }
    .ov__wrap__p {
        margin: 10px 0;
        text-align: center;
    }
    .ov__wrap__p p {
        text-align: center;
        text-indent: 0;
    }
    .contact__row {
        flex-direction: column;
        margin-top: 10px;
    }
    .ov__body__position {
        text-align: center;
    }
    .ov__skill__content {
        flex-direction: column;
    }
    .section__bottom, .outter__bottom {
        height: fit-content;
    }
    .lightmode__ul, .darkmode__ul {
        width: 100%;
        padding: 10px;
    }
    .ov__skill__content, .ov__skill__wrap__sre {
        width: 100%;
    }
}
@media only screen  and (max-width: 1180px) and (min-width: 900px) {
    .section__bottom {
        height: fit-content;
    }
    .lightmode__ul, .darkmode__ul {
        width: 100%;
        padding: 10px;
    }
    .ov__skill__content, .ov__skill__wrap__sre {
        width: 90%;
    }
    .ov__skill__wrap__sre {
        margin-left: 20px;
    }
    .ov__wrap__skill {
        align-items: center;
    }
    .container {
        width: 95%;
    }
    .section__bottom, .outter__bottom {
        height: fit-content;
    }
}