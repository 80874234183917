.exp__container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow: hidden;
    border-radius: 30px;
}

.exp__wrap__item {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 5px;
}

.exp__wrap__item::-webkit-scrollbar {
    width: 5px;
}

.exp__wrap__item::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.4);
}

.exp__wrap__item::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.6);
}

.exp__item {
    background-color: rgb(255, 255, 255, .4);
    /* border: 1px solid #e7e7e9; */
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    transition: .3s;
    z-index: 999;
}

.exp__item:hover {
    transform: scale(1.02);
    background-color: rgb(255, 255, 255, .7);
}

.item__dark {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
}

.item__dark:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.exp__item__top {
    display: flex;
    justify-content: space-between;
}

.exp__item__name {
    height: fit-content;
    font-weight: 500;
    color: white;
    background-color: #e7c56e;
    padding: 0 5px;
    border-radius: 3px;
}

.item__name__dark {
    background-color: #3d7fa1;
}

.exp__item__desc {
    height: fit-content;
    border-left: 2px solid #e7c56e;
    padding-left: 10px;
    margin-top: 10px;
}

.item__desc__dark {
    border-left: 2px solid #3d7fa1;
}

.wrap__blob {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    filter: blur(50px);
}

.exp__item__menu {
    display: flex;
}

.item__info {
    color: rgb(33, 33, 33);
    font-size: 14px;
    margin-top: 10px;
}

.item__info__dark {
    color: rgb(210, 210, 210);
    font-size: 14px;
    margin-top: 10px;
}

.item__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    transition: .3s;
}

.item__link:hover {
    background-color: #e7c56e;
}

.link__dark:hover {
    background-color: #3d7fa1;
}

.item__link img {
    width: 16px;
}