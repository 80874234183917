.wrapper {
    position: relative;
    background-color: rgb(243, 244, 246);
    transition: 0.7s;
}

.wrapper-dark {
    background-color: rgb(32, 32, 34);
    transition: 0.7s;
}

.sectione__top {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    transition: 0.7s;
}

.sectione__top-dark {
    color: white;
    background-color: hsla(240, 4%, 13%, 1);
    background-image: radial-gradient(
            at 40% 20%,
            rgba(255, 184, 122, 0.4) 0px,
            transparent 50%
        ),
        radial-gradient(at 80% 0%, rgba(31, 221, 255, 0.2) 0px, transparent 50%),
        radial-gradient(
            at 0% 50%,
            rgba(255, 219, 222, 0.4) 0px,
            transparent 50%
        ),
        radial-gradient(
            at 88% 50%,
            rgba(255, 133, 173, 0.4) 0px,
            transparent 50%
        ),
        radial-gradient(
            at 0% 0%,
            hsla(343, 100%, 76%, 0.4) 0px,
            transparent 50%
        );
    transition: 0.7s;
}

.sectione__top-light {
    background-color: rgb(243, 244, 246);
    background-image: radial-gradient(
            at 40% 20%,
            hsla(28, 100%, 74%, 1) 0px,
            transparent 50%
        ),
        radial-gradient(at 80% 0%, hsla(189, 100%, 56%, 1) 0px, transparent 50%),
        radial-gradient(at 0% 50%, hsla(355, 100%, 93%, 1) 0px, transparent 50%),
        radial-gradient(
            at 88% 50%,
            hsla(340, 100%, 76%, 1) 0px,
            transparent 50%
        ),
        radial-gradient(at 0% 0%, hsla(343, 100%, 76%, 1) 0px, transparent 50%);
    transition: 0.7s;
}

.section__bottom {
    width: 100vw;
    height: 100vh;
    /* height: fit-content; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: 0.7s;
    padding: 2vh 0;
}

.outter__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.wrap__header {
    margin-left: 2%;
}

.sectione__top h1 {
    font-size: 6rem;
    font-weight: 700;
}

.sectione__top h2 {
    width: fit-content;
    padding: 4px 10px;
    font-size: 1.3rem;
    font-weight: 400;
    border-radius: 7px;
    color: white;
    /* background-color: #fca5a5; */
    background-color: #e7c56e;
    margin-top: 20px;
}

.sectione__top-dark h2 {
    /* background-color: #7dd3fc; */
    background-color: #3d7fa1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.type::after {
    content: "|";
    font-weight: 400;
    animation: cursor 1.1s infinite step-start;
}

.scroll__container {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: wave 2s infinite;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    opacity: 0.3;
    cursor: pointer;
    border: 0;
    background-color: transparent;
}

.scroll__container-dark {
    color: white;
}

.wrap__chevron {
    width: 100%;
}

.chevron {
    width: 30px;
}

.mode__btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 6px;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 2;
    transition: 0.5s;
}

.mode__btn img {
    width: 20px;
}

.dark-btn {
    background-color: #3d7fa1;
    box-shadow: 5px 5px 0 #2b6886;
    transition: 0.5s;
}

.light-btn {
    background-color: #e7c56e;
    box-shadow: 5px 5px 0 #ccac5d;
    transition: 0.5s;
}

.container {
    width: 50%;
    height: 90%;
    /* padding: 10px; */
    border-radius: 30px;
    border: 1px solid #e7e7e9;
    background-color: #fff;
    margin-top: 20px;
    z-index: 0;
}

.container-dark {
    background-color: #1c1c1e;
    border: 1px solid #3d3c41;
}

.menus {
    width: 50%;
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    border: 1px solid #e7e7e9;
    background-color: #fff;
    overflow: hidden;
}

.menus-dark {
    color: white;
    background-color: #1c1c1e;
    border: 1px solid #3d3c41;
}

.menu-light,
.menu-dark {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 12px 0;
    cursor: pointer;
    transition: 0.3s;
}

.menu-light:hover {
    background-color: #e7e7e9;
}

.menu-dark:hover {
    background-color: rgb(48, 48, 48);
}

.border-btw {
    border-left: 1px solid #e7e7e9;
    border-right: 1px solid #e7e7e9;
}

.border-btw-dark {
    border-left: 1px solid #3d3c41;
    border-right: 1px solid #3d3c41;
}

.menu-active {
    color: white;
    background-color: #e7c56e;
    flex: 1;
    height: 100%;
}

.menu-active:hover {
    cursor: default;
    background-color: #e7c56e;
}

.menu-active-dark {
    background-color: #3d7fa1;
}

.menu-active-dark:hover {
    cursor: default;
    background-color: #3d7fa1;
}

@keyframes wave {
    0% {
        bottom: 20px;
    }
    50% {
        bottom: 40px;
        opacity: 0.2;
    }
    100% {
        bottom: 20px;
    }
}

@keyframes fadeIn {
    0% {
        border-radius: 6px;
    }
    50% {
        border-radius: 100px;
    }
    100% {
        border-radius: 6px;
    }
}

@keyframes fadeOut {
    0% {
        margin-bottom: 0;
    }
    50% {
        margin-top: -50px;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 0;
    }
}

@media screen and (max-width: 400px) {
    .sectione__top h1 {
        font-size: 50px;
    }
    .sectione__top h2 {
        font-size: 20px;
    }
    .mode__btn {
        right: 10px;
        top: 10px;
    }
    .menus {
        width: 95%;
    }
    .container {
        width: 95%;
    }
}
@media screen and (max-width: 900px) {
    .sectione__top h1 {
        font-size: 42px;
    }
    .sectione__top h2 {
        font-size: 20px;
    }
    .mode__btn {
        right: 10px;
        top: 10px;
    }
    .menus {
        width: 95%;
    }
    .container {
        width: 95%;
    }
}