.resume__container {
    padding: 20px;
    height: 100%;
    border-radius: 30px;
    text-align: center;
}

.pdf {
    border-radius: 30px;
    background-color: white;
}

.resume__container object p a {
    text-decoration: underline;
}